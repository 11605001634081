import React, { useState, useEffect, useCallback } from "react";
import YearDropDown from "../../DropDowns/YearDropDown";
import BankDropdoun from "../../DropDowns/BankDropdoun";
import { apiUrl } from "../../../Components/apiConfig";
import { formatDate } from "../../../Components/dateFormate";
import { useParams } from "react-router-dom";
import { useUser } from "../../../Components/UserContext";
import axios from "axios";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Interval_in_Months = Array.from({ length: 12 }, (_, index) => index + 1);

const SetInstallment = ({ studentData, feesData, showLoader, hideLoader, loadData }) => {
  const { Sr_No } = useParams();
  const { userData } = useUser();
  const [errors, setErrors] = useState({});
  const [isProceed, setIsProceed] = useState(false);
  const [gstPercent, setgstPercent] = useState();


  useEffect(() => {
    fetchData();
  }, [userData, studentData?.Course_Id]);

  const fetchData = async () => {
    if (userData) {
      try {
        showLoader();

        const result = await axios.get(
          `${apiUrl}/getIsGST?HeadId=${userData?.Head_Id}&OrgId=${userData?.Org_Id}`
        );
        console.log(result?.data);
        setgstPercent(result?.data);

        if (userData?.Org_Id && studentData?.Course_Id) {
          const res = await axios.get(
            `${apiUrl}/getCourseByCourseId?CourseId=${studentData?.Course_Id}&OrgId=${userData?.Org_Id}`
          );
          console.log(userData);
          console.log(res.data);
          setInstallment({
            ...installment,
            Fees: res?.data?.Total_Course_Fees,
            Discounted_Fees: res?.data?.Total_Course_Fees,
            Net_Amount: res?.data?.Total_Course_Fees,
            Full_Payment_Amt: res?.data?.Total_Course_Fees,
            Books_Fees: res?.data?.Book_Fees,
          });
        }

        hideLoader();
      } catch (error) {
        console.error("Error fetching headmaster data:", error);
        hideLoader();
      }
    }
  };

  const [installment, setInstallment] = useState({
    Fees: "",
    Discount_Amount: "0",
    Discounted_Fees: "",
    Full_Payment: false,
    Full_Payment_Amt: "0",
    Down_Payment: false,
    Set_Installment: false,
    Financial_Year: "",
    Amount: "0",
    Payment_Date: "",
    Payment_Mode: "Cash",
    Bank: "",
    Cheque_No: "",
    Cheque_Date: "",
    Cheque_Status: "Uncleared",
    Refrence_No: "",
    Payment_Type: "Installments",
    Net_Amount: "",
    No_of_Installments: "1",
    Installment_Start_Date: new Date().toISOString().split("T")[0],
    Interval_in_Months: "1",
    Books_Fees: "",
    Remark: "",
  });
  const [installments, setInstallments] = useState([]);
  const [totalAmount, setTotalAmount] = useState("0");

  useEffect(() => {
    const startDate = new Date(installment.Installment_Start_Date);
    let installmentAmount =
      parseFloat(installment.Net_Amount) /
      parseInt(installment.No_of_Installments);
    
    const newInstallments = [];
    for (let i = 0; i < parseInt(installment.No_of_Installments); i++) {
      const dueDate = new Date(startDate);
      dueDate.setMonth(
        startDate.getMonth() + i * parseInt(installment.Interval_in_Months)
      );
  
      if (installment.Payment_Type === "Full Payment") {
        newInstallments.push({
          Due_Date: dueDate,
          Amount: installmentAmount.toFixed(2),
        });
      } else {
        let roundedAmount = Math.floor(installmentAmount);
  
        if (i === parseInt(installment.No_of_Installments) - 1) {
          roundedAmount =
            parseFloat(installment.Net_Amount) -
            roundedAmount * (parseInt(installment.No_of_Installments) - 1);
        }
  
        newInstallments.push({
          Due_Date: dueDate,
          Amount: roundedAmount.toFixed(2),
        });
      }
    }
    
    setInstallments(newInstallments);
  }, [installment.Installment_Start_Date, installment.Net_Amount, installment.No_of_Installments, installment.Interval_in_Months, installment.Payment_Type]);
  

  console.log(installment);
  console.log(installments);


  const handleProceedSubmit = (e) => {
    e.preventDefault();

    setIsProceed(true);
  };

  useEffect(() => {
    const calculatedTotalAmount = installments
      ?.reduce((total, installment) => total + parseFloat(installment.Amount || 0), 0)
      .toFixed(2);
    setTotalAmount(calculatedTotalAmount);
  }, [installments]);

  console.log(studentData, feesData);

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;

    const errors = {};



    // Add down payment if Down_Payment is true and amount is greater than 0
    if (installment.Down_Payment && parseFloat(installment.Amount) > 0) {
      if (installment.Full_Payment !== true && parseFloat(installment.Net_Amount).toFixed(2) !==parseFloat(totalAmount).toFixed(2)){
        errors.Net_Amount = 'Net Amount And Total Amount must be equal';
        toast.error(errors.Net_Amount); 
    } else{
      setInstallments((prevInstallments) => [
        {
          Due_Date: installment.Payment_Date,
          Amount: parseFloat(installment.Amount).toFixed(2),
        },
        ...prevInstallments,
      ]);}
    } else if (installment.Down_Payment) {
      errors.Amount = 'Down payment amount must be greater than 0.';
    }



    const InsertResult = {
      Installment:
        installment.Full_Payment === true
          ? [
            {
              Due_Date: new Date(installment.Payment_Date)
                .toISOString()
                .split("T")[0],
              Amount: installment.Full_Payment_Amt,
            },
          ]
          : installments,
      Std_Id: studentData.Std_Id,
      Std_Name: studentData.Std_Name,
      Batch_ID: studentData.Batch_ID,
      Batch_Name: studentData.Batch_Name,
      ID: Sr_No,
      Student_Id: studentData.Student_Id,
      UUID: studentData.UUID,
      Name: studentData.Name,
      Books_Fees: installment.Books_Fees,
      Full_Payment: installment.Full_Payment,
      Down_Payment: installment.Down_Payment,
      Set_Installment: installment.Set_Installment,
      Net_Amount: installment.Net_Amount,
      Full_Payment_Amt: installment.Full_Payment_Amt,
      T_GST_Percent: gstPercent,
      Tuition_Fees: installment.Fees,
      T_Discount: installment.Discount_Amount,
      T_Discounted_Amt: installment.Discounted_Fees,
      Financial_Year: installment.Financial_Year,
      Payment_Date: installment.Payment_Date,
      Payment_Mode: installment.Payment_Mode,
      Bank_Name: installment.Bank,
      Cheque_No: installment.Cheque_No,
      Cheque_Date: installment.Cheque_Date,
      Cheque_Status: installment.Cheque_Status,
      Reference: installment.Refrence_No,
      Head_Id: userData.Head_Id,
      Head_Name: userData.Head_Name,
      User_Id: userData?.UserId,
      Org_Id: userData?.Org_Id,
      Org_Name: userData?.Org_Name,
      User_Role: userData?.Role,
      LoginUsername: userData?.Username,
      Display_Name: userData?.UserName
    };



    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/SetInstallments`, InsertResult)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Student Installment has been Set Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              //navigate("/viewActiveStudent");
              loadData();
              fetchData();
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire(
            "Server Timeout",
            "Server is Busy!!!, Please try again later.",
            "error"
          );

          hideLoader();
        });
    } else {
      setErrors(errors);
    }
  };

  const handleDueDateChange = (index, event) => {
    const newInstallments = [...installments];
    newInstallments[index].Due_Date = event.target.value;
    setInstallments(newInstallments);
  };

  const handleAmountChange = (index, event) => {
    const newInstallments = [...installments];
    newInstallments[index].Amount = event.target.value;
    setInstallments(newInstallments);
  };

  return (
    <div
      className="card shadow-none border border-300 my-4"
      data-component-card="data-component-card"
    >
      <div className="card-body">
        <h5>Set Installment</h5>
        <hr />
        <div className="row p-2 g-3">
          <div className="col-md-6">
            <form
              onSubmit={
                installment?.Full_Payment === true
                  ? handleSubmit
                  : handleProceedSubmit
              }
            >
              <h6 className="mt-1">Tuition Fees Details</h6>
              <hr />
              <div className="row">
                <div className="mb-3 col-md-4">
                  <label className="form-label" htmlFor="Fees">
                    Fees :
                  </label>
                  <input
                    className={`form-control form-control-sm`}
                    type="text"
                    name="Fees"
                    value={installment.Fees}
                    id="Fees"
                    onChange={(e) => {
                      const input = e.target.value;
                      const decimalInput = input.replace(/[^0-9.]/g, "");
                      const amount = decimalInput.replace(
                        /^(\d*\.\d*)\..*$/,
                        "$1"
                      );
                      setInstallment({
                        ...installment,
                        Discounted_Fees: amount,
                        Net_Amount: amount,
                        Fees: amount,
                        Full_Payment_Amt: amount,
                        Discount_Amount: "0",
                      });
                    }}
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <label className="form-label" htmlFor="Discount_Amount">
                    Discount Amount :
                  </label>
                  <input
                    className={`form-control form-control-sm`}
                    type="text"
                    name="Discount_Amount"
                    id="Discount_Amount"
                    value={installment.Discount_Amount}
                    onChange={(e) => {
                      const input = e.target.value;
                      const decimalInput = input.replace(/[^0-9.]/g, "");
                      const amount = decimalInput.replace(
                        /^(\d*\.\d*)\..*$/,
                        "$1"
                      );

                      let dis_Amount = installment?.Fees - amount;

                      if (parseFloat(installment.Fees) <= parseFloat(amount)) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Discount Amount Cannot Exceed Fees.",
                        });
                      } else {
                        setInstallment({
                          ...installment,
                          Discount_Amount: amount,
                          Discounted_Fees: dis_Amount,
                          Net_Amount: dis_Amount,
                          Full_Payment_Amt: dis_Amount,
                          Amount: "0",
                        });
                      }
                    }}
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <label className="form-label" htmlFor="Discounted_Fees">
                    Discounted Fees :
                  </label>
                  <input
                    className={`form-control form-control-sm`}
                    type="text"
                    value={installment?.Discounted_Fees}
                    name="Discounted_Fees"
                    id="Discounted_Fees"
                    disabled
                  // onChange={(e) => {
                  //   setInstallment({
                  //     ...installment,

                  //     Discounted_Fees: e.target.value,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="flexCheckDefault1"
                  type="checkbox"
                  checked={installment.Full_Payment}
                  onChange={(e) => {
                    setIsProceed(false);
                    setInstallment({
                      ...installment,
                      Full_Payment: e.target.checked,
                      Down_Payment: false,
                      Set_Installment: false,

                    });
                  }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault1">
                  <h6>Select To Make Full Payment</h6>
                </label>
              </div>
              <hr />
              {installment?.Full_Payment === true && (
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Financial_Year">
                      Financial Year :
                    </label>
                    <YearDropDown
                      name="Financial_Year"
                      id="Financial_Year"
                      value={installment?.Financial_Year}
                      required
                      isClearable
                      onChange={(selectedOption) => {
                        //console.log(selectedOption);
                        setInstallment({
                          ...installment,
                          Financial_Year: selectedOption ? selectedOption.value : "",
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Amount">
                      Amount :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="text"
                      name="Amount"
                      id="Amount"
                      disabled
                      value={installment.Full_Payment_Amt}
                      onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9.]/g, "");
                        const amount = decimalInput.replace(
                          /^(\d*\.\d*)\..*$/,
                          "$1"
                        );
                        // setInstallment({
                        //   ...installment,
                        //   Full_Payment_Amt: amount,
                        //  // Net_Amount: net_Amount,
                        // });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Payment_Date">
                      Payment Date :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="date"
                      name="Payment_Date"
                      id="Payment_Date"
                      required
                      onChange={(e) => {
                        setInstallment({
                          ...installment,
                          Payment_Date: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Payment_Mode">
                      Payment Mode :
                    </label>
                    <select
                      className={`form-select form-select-sm`}
                      type="text"
                      name="Payment_Mode"
                      id="Payment_Mode"
                      onChange={(e) => {
                        setInstallment({
                          ...installment,
                          Payment_Mode: e.target.value,
                        });
                      }}
                    >
                      <option value="Cash">Cash</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Card">Card</option>
                      <option value="Online">Online</option>
                    </select>
                  </div>

                  {["Cheque"].includes(installment.Payment_Mode) && (
                    <>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Bank">
                          Bank Name :
                        </label>
                        <BankDropdoun
                          value={installment.Bank ? [{ value: installment.Bank, label: installment.Bank }] : null}
                          name="Bank"
                          id="Bank"
                          onChange={(e) => {
                            setInstallment({
                              ...installment,
                              Bank: e.value,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Cheque_No">
                          Cheque No :
                        </label>
                        <input
                          className={`form-control form-control-sm`}
                          type="text"
                          name="Cheque_No"
                          id="Cheque_No"
                          value={installment.Cheque_No}
                          onChange={(e) => {
                            const input = e.target.value;
                            const decimalInput = input.replace(/[^0-9]/g, "");
                            setInstallment({
                              ...installment,
                              Cheque_No: decimalInput,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Cheque_Date">
                          Cheque Date :
                        </label>
                        <input
                          className={`form-control form-control-sm`}
                          type="date"
                          name="Cheque_Date"
                          id="Cheque_Date"
                          onChange={(e) => {
                            setInstallment({
                              ...installment,
                              Cheque_Date: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Cheque_Status">
                          Cheque Status :
                        </label>
                        <select
                          className={`form-select form-select-sm`}
                          type="text"
                          name="Cheque_Status"
                          id="Cheque_Status"
                          value={installment.Cheque_Status}
                          onChange={(e) => {
                            setInstallment({
                              ...installment,
                              Cheque_Status: e.target.value,
                            });
                          }}
                        >
                          <option value="Uncleared">Uncleared</option>
                          <option value="Honoured">Honoured</option>
                          <option value="Dishonoured">Dishonoured</option>
                        </select>
                      </div>
                    </>
                  )}

                  {["Cheque", "Card", "Online"].includes(
                    installment?.Payment_Mode
                  ) && (
                      <>
                        <div className="mb-3 col-md-12">
                          <label className="form-label" htmlFor="Refrence_No">
                            Refrence No:
                          </label>
                          <input
                            className={`form-control form-control-sm`}
                            type="text"
                            name="Refrence_No"
                            id="Refrence_No"
                            onChange={(e) => {
                              setInstallment({
                                ...installment,
                                Refrence_No: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                </div>
              )}

              <div className="form-check">
                <input
                  className="form-check-input"
                  id="flexCheckDefault1"
                  type="checkbox"
                  checked={installment.Down_Payment}
                  onChange={(e) => {
                    setInstallment({
                      ...installment,
                      Down_Payment: e.target.checked,
                      Full_Payment: false,
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault1">
                  <h6>Select To Make Down Payment</h6>
                </label>
              </div>
              <hr />
              {installment?.Down_Payment === true && (
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Financial_Year">
                      Financial Year :
                    </label>
                    <YearDropDown
                      name="Financial_Year"
                      id="Financial_Year"
                      value={installment.Financial_Year}
                      required={true}
                      isClearable
                     onChange={(selectedOption) => {
                        //console.log(selectedOption);
                        setInstallment({
                          ...installment,
                          Financial_Year: selectedOption ? selectedOption.value : "",
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Amount">
                      Amount :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="text"
                      name="Amount"
                      id="Amount"
                      value={installment.Amount}
                      onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9.]/g, "");
                        const amount = decimalInput.replace(
                          /^(\d*\.\d*)\..*$/,
                          "$1"
                        );

                        let net_Amount = installment?.Discounted_Fees - amount;

                        if (
                          parseFloat(installment?.Discounted_Fees) <=
                          parseFloat(amount)
                        ) {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Amount cannot exceed Discounted Fees.",
                          });
                        } else {
                          setInstallment({
                            ...installment,
                            Amount: amount,
                            Net_Amount: net_Amount,
                          });
                        }
                      }}
                    />
                    <span style={{ color: "Red", fontSize: '12px' }}>{errors.Amount}</span>
                  </div>

                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Payment_Date">
                      Payment Date :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="date"
                      name="Payment_Date"
                      id="Payment_Date"
                      onChange={(e) => {
                        setInstallment({
                          ...installment,
                          Payment_Date: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Payment_Mode">
                      Payment Mode :
                    </label>
                    <select
                      className={`form-select form-select-sm`}
                      type="text"
                      name="Payment_Mode"
                      id="Payment_Mode"
                      onChange={(e) => {
                        setInstallment({
                          ...installment,
                          Payment_Mode: e.target.value,
                        });
                      }}
                    >
                      <option value="Cash">Cash</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Card">Card</option>
                      <option value="Online">Online</option>
                    </select>
                  </div>

                  {["Cheque"].includes(installment.Payment_Mode) && (
                    <>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Bank">
                          Bank Name:
                        </label>
                        <BankDropdoun
                          value={installment.Bank ? [{ value: installment.Bank, label: installment.Bank }] : null}
                          name="Bank"
                          id="Bank"
                          onChange={(selectedOption) => {
                            setInstallment({
                              ...installment,
                              Bank: selectedOption ? selectedOption.value : null,
                            });
                          }}
                        />
                      </div>

                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Cheque_No">
                          Cheque No :
                        </label>
                        <input
                          className={`form-control form-control-sm`}
                          type="text"
                          name="Cheque_No"
                          id="Cheque_No"
                          value={installment.Cheque_No}
                          onChange={(e) => {
                            const input = e.target.value;
                            const decimalInput = input.replace(/[^0-9]/g, "");
                            setInstallment({
                              ...installment,
                              Cheque_No: decimalInput,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Cheque_Date">
                          Cheque Date :
                        </label>
                        <input
                          className={`form-control form-control-sm`}
                          type="date"
                          name="Cheque_Date"
                          id="Cheque_Date"
                          onChange={(e) => {
                            setInstallment({
                              ...installment,
                              Cheque_Date: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Cheque_Status">
                          Cheque Status :
                        </label>
                        <select
                          className={`form-select form-select-sm`}
                          type="text"
                          name="Cheque_Status"
                          id="Cheque_Status"
                          value={installment.Cheque_Status}
                          onChange={(e) => {
                            setInstallment({
                              ...installment,
                              Cheque_Status: e.target.value,
                            });
                          }}
                        >
                          <option value="Uncleared">Uncleared</option>
                          <option value="Honoured">Honoured</option>
                          <option value="Dishonoured">Dishonoured</option>
                        </select>
                      </div>
                    </>
                  )}

                  {["Cheque", "Card", "Online"].includes(
                    installment?.Payment_Mode
                  ) && (
                      <>
                        <div className="mb-3 col-md-12">
                          <label className="form-label" htmlFor="Refrence_No">
                            Refrence No:
                          </label>
                          <input
                            className={`form-control form-control-sm`}
                            type="text"
                            name="Refrence_No"
                            id="Refrence_No"
                          />
                        </div>
                      </>
                    )}
                </div>
              )}
              <div class="form-check">
                <input
                  className="form-check-input"
                  id="flexCheckDefault2"
                  type="checkbox"
                  checked={installment.Set_Installment}
                  onChange={(e) => {
                    setInstallment({
                      ...installment,
                      Set_Installment: e.target.checked,
                      Full_Payment: false,
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault2">
                  <h6>Select To Set Installment</h6>
                </label>
              </div>
              <hr />
              {installment?.Set_Installment === true && (
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="Payment_Type">
                      Payment Type :
                    </label>
                    <select
                      className={`form-select form-select-sm`}
                      type="text"
                      name="Payment_Type"
                      id="Payment_Type"
                      value={installment.Payment_Type}
                      disabled
                      onChange={(e) => {
                        setInstallment({
                          ...installment,
                          Payment_Type: e.target.value,
                        });
                      }}
                    >
                      <option value="Full Payment">Full Payment</option>
                      <option value="Installments">Installments</option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label
                      className="form-label"
                      htmlFor="Installment_Start_Date"
                    >
                      Installment Start Date :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="date"
                      name="Installment_Start_Date"
                      id="Installment_Start_Date"
                      value={
                        installment.Installment_Start_Date
                          ? dayjs(installment.Installment_Start_Date).format(
                            "YYYY-MM-DD"
                          )
                          : ""
                      }
                      onChange={(e) => {
                        setInstallment({
                          ...installment,
                          Installment_Start_Date: e.target.value
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="Net_Amount">
                      Net Amount :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="text"
                      name="Net_Amount"
                      id="Net_Amount"
                      value={installment.Net_Amount}
                      disabled={true}
                    // onChange={(e) => {
                    //   setInstallment({
                    //     ...installment,
                    //     Net_Amount: e.target.value,
                    //   });
                    // }}
                    />
                  </div>

                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="No_of_Installments">
                      No. of Installments :
                    </label>
                    <input
                      className={`form-control form-control-sm`}
                      type="text"
                      name="No_of_Installments"
                      id="No_of_Installments"
                      value={installment.No_of_Installments}
                      disabled={installment.Payment_Type !== "Installments"}
                      onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9]/g, "");
                        setInstallment({
                          ...installment,
                          No_of_Installments: decimalInput,
                        });
                      }}
                    />
                  </div>

                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="Interval_in_Months">
                      Interval in Months :
                    </label>
                    <select
                      className={`form-select form-select-sm`}
                      type="text"
                      name="Interval_in_Months"
                      id="Interval_in_Months"
                      value={installment.Interval_in_Months}
                      onChange={(e) => {
                        setInstallment({
                          ...installment,
                          Interval_in_Months: parseInt(e.target.value),
                        });
                      }}
                    >
                      {Interval_in_Months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="mb-3 col-md-12">
                <label className="form-label" htmlFor="Remark">
                  Remark/Note :
                </label>
                <textarea
                  className={`form-control form-control-sm`}
                  type="text"
                  name="Remark"
                  rows={2}
                  id="Remark"
                  onChange={(e) => {
                    setInstallment({
                      ...installment,
                      Remark: e.target.value,
                    });
                  }}
                />
              </div>
              {installment.Set_Installment === true && (
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-sm btn-primary mb-1"
                      type="submit"
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              )}
              {installment.Full_Payment === true && (
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-sm btn-primary mb-1"
                      type="submit"
                    >
                      Pay
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>

          <div className="col-md-6">
      {isProceed === true && (
        <>
          <table
            cellspacing="0"
            cellpadding="4"
            align="Center"
            rules="cols"
            className="table table-bordered border-primary table-hover table-responsive"
            style={{ border: "1px solid var(--phoenix-gray-200)" }}
          >
            <thead>
              <tr align="center">
                <th align="center" scope="col">#</th>
                <th align="center" scope="col">Due Date</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              {installments?.map((installment, index) => (
                <tr key={index} style={{ backgroundColor: "White" }}>
                  <td align="center" style={{ width: "30px" }}>
                    <span>{index + 1}</span>
                  </td>
                  <td align="center" style={{ width: "150px" }}>
                    <input
                      name="Due_Date"
                      type="date"
                      value={
                        installment.Due_Date
                          ? dayjs(installment.Due_Date).format("YYYY-MM-DD")
                          : ""
                      }
                      id="Due_Date"
                      className="form-control form-control-sm"
                     onChange={(e) => handleDueDateChange(index, e)}
                    />
                  </td>
                  <td style={{ width: "50px", paddingRight: "10px" }}>
                    <input
                      name="Amount"
                      type="text"
                      value={installment.Amount}
                      id="Amount"
                      className="form-control form-control-sm"
                    onChange={(e) => handleAmountChange(index, e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr align="center" style={{ fontWeight: "bold" }}>
                <td>&nbsp;</td>
                <td align="right">
                  <span>Total Amount : </span>
                </td>
                <td>
                  <span>
                    {installments
                      ?.reduce(
                        (total, installment) =>
                          total + parseFloat(installment.Amount || 0),
                        0
                      )
                      .toFixed(2)}
                  </span>
                </td>
              </tr>
            </tfoot>
          </table>
          <div className="row mt-5">
            <div className="col-12 text-center">
              <button
                className="btn btn-sm btn-primary mb-1"
                onClick={handleSubmit}
              >
                Set Installment
              </button>
            </div>
          </div>
        </>
      )}
    </div>
    <ToastContainer position="top-center" />

        </div>
      </div>
    </div>
  );
};

export default SetInstallment;
