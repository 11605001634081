import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Components/useFullPageLoader";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
//import "bootstrap/dist/css/bootstrap.min.css";
import { useUser } from "../../Components/UserContext";
import Student from "../Students/AddUpdateStudent";
import axios from "axios";
import { apiUrl } from "../../Components/apiConfig";
import { formatDate } from "../../Components/dateFormate";
import VerticalDivider from "../../Components/VerticalDivider";
import StudentPerformance from "./StudentPerformance";
import FeesPayment from "./Fees/FeesPayment";
import MasterPage from "./Fees/MasterPage";
import Biometric from "./Biometric/Biometric";
import Message from "./Message";
import "./toggle.css";
import { BsArrowRightSquareFill } from "react-icons/bs";
import { BsArrowLeftSquareFill } from "react-icons/bs";

const StudentProfile = () => {
    const { Sr_No } = useParams();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { userData } = useUser();
    const [key, setKey] = useState(1);


    const handleTabSelect = (selectedKey) => {
        setKey(parseInt(selectedKey));
    };

    const [studentData, setStudentData] = useState();
    const [feesData, setFeesdata] = useState();

    useEffect(() => {
        fetchData();
    }, [userData, Sr_No]);

    const fetchData = async () => {
        if (userData && userData.Org_Id && Sr_No) {
            try {
                showLoader();
                const res = await axios.get(
                    `${apiUrl}/getStudentProfile?ID=${Sr_No}&OrgId=${userData.Org_Id}`
                );
                setStudentData(res.data.studentData);
                setFeesdata(res.data.feesData);
                console.log(res.data.studentData);
                console.log(res.data.feesData);
                hideLoader();
            } catch (error) {
                console.error("Error fetching headmaster data:", error);
                hideLoader();
            }
        }
    };

    const CountWithCommas = (number) => {
        if (number === null || isNaN(number)) {
            return "0";
        }
        return number.toLocaleString();
    };

    const [showAdditionalContent, setShowAdditionalContent] = useState(true);

    const handleButtonClick = () => {
        // Toggle the state when the button is clicked
        setShowAdditionalContent(!showAdditionalContent);
    };

    return (
        <>
            <div className="content">
                <h4 className="text-900 mb-0" data-anchor="data-anchor" id="basic-form">
                    <div className="text-end">
                        <button className="btn btn-sm" onClick={handleButtonClick}>
                            {showAdditionalContent ? <i style={{ fontSize: "2em" }}><BsArrowRightSquareFill /></i> :
                                <i style={{ fontSize: "2em" }}><BsArrowLeftSquareFill /></i>}
                        </button>
                    </div>

                </h4>
                <div className="row">
                    <div className={showAdditionalContent ? "col-12 col-xl-8" : "col-12 col-xl-12"} style={{
                        WebkitTransition: 'all 0.5s ease',
                        MozTransition: 'all 0.5s ease',
                        OTransition: 'all 0.5s ease',
                        transition: 'all 0.5s ease'
                    }}>
                        <div className="row p-3">
                            <div className="card">
                                <Tabs
                                    defaultActiveKey={1}
                                    id="fill-tab-example"
                                    fill
                                    activeKey={key}
                                    onSelect={handleTabSelect}
                                    className="mt-4 mb-2 custom-tabs-container"
                                >
                                    <Tab eventKey={1} title="Profile">
                                        {key === 1 && <Student />}
                                    </Tab>
                                    <Tab eventKey={2} title="Performance">
                                        {key === 2 && (
                                            <StudentPerformance />
                                        )}
                                    </Tab>
                                    <Tab eventKey={3} title="Fees ">
                                        {key === 3 && (
                                            <MasterPage feesData={feesData} studentData={studentData} showLoader={showLoader} hideLoader={hideLoader} loadData={fetchData} />
                                        )}


                                    </Tab>
                                    <Tab eventKey={4} title="Message ">
                                        {key === 4 && (
                                            <Message Sr_No={Sr_No} />
                                        )}


                                    </Tab>
                                    <Tab eventKey={5} title="Biometric Report">
                                        {key === 5 && (
                                            <Biometric Sr_No={Sr_No} />
                                        )}


                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <div className={showAdditionalContent ? "col-12 col-xl-4 mt-3" : "d-none"}>

                        <div className="card">
                            <div className="card-body d-flex flex-column justify-content-between pb-3">
                                <div className="row align-items-center g-5 mb-3 text-center text-sm-start">
                                    <div className="col-12 col-sm-auto mb-sm-2">
                                        <div className="avatar avatar-4xl">
                                            <img className="rounded-circle img-thumbnail bg-white shadow-sm"

                                                width="200" src={`${apiUrl}/Documents/Student/Photos/${studentData?.Photo}`}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop
                                                    e.target.src = 'https://masyseducare.com/img/userdefault.png'; // Specify the path to your default image
                                                }} alt="Photos" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-auto flex-1">
                                        <h3 className="me-2">{studentData?.Name}</h3>
                                        {/* <p className="text-800">Joined 3 months ago</p> */}

                                    </div>
                                </div>
                                <div className="d-block  border-top border-dashed border-300 pt-4">
                                    <span className="badge badge-phoenix badge-phoenix-primary">
                                        Student Details :</span>
                                    <div className="row flex-wrap mb-1 mt-2">
                                        <div className="col-md-12">
                                            <strong>Student ID :</strong><span className="fw-semi-bold ms-1 me-4">{studentData?.Student_Id}</span>
                                        </div>
                                        <div className="col-md-12">
                                            <strong>Biometric ID:</strong><span className="fw-semi-bold ms-1 me-4">{studentData?.UUID}</span>
                                        </div>
                                    </div>
                                    <div className="row flex-wrap mb-1 ">
                                        <div className="col-md-6">
                                            <strong>Gender :</strong><span className="fw-semi-bold ms-1 me-4">{studentData?.Gender}</span>

                                        </div>
                                        <div className="col-md-6">
                                            <strong>DOB :</strong><span className="fw-semi-bold ms-1 me-4">{formatDate(studentData?.DOB)}</span>

                                        </div>
                                    </div>
                                    <div className="d-block flex-wrap mb-1 ">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Course{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.Course_Name}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Standard{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.Std_Name}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Batch{" "}
                                        </strong>
                                        :{" "}
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.Batch_Name}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1 ">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Mobile No{" "}

                                            :{" "}</strong>
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.Mobile_For_SMS}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1 ">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Email{" "}

                                            :{" "}</strong>
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.Email}
                                        </span>
                                    </div>
                                    <span className="badge badge-phoenix badge-phoenix-primary mt-2">
                                        Parent Details :</span>
                                    <div className="d-block flex-wrap mb-1 mt-2">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Father Name{" "}

                                            :{" "}</strong>
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.Father_Name}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1 ">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Father Mobile No{" "}

                                            :{" "}</strong>
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.Father_Mobile}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Mother Name{" "}

                                            :{" "}</strong>
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.Mother_Name}
                                        </span>
                                    </div>
                                    <div className="d-block flex-wrap mb-1 ">
                                        <strong
                                            className="mb-0"
                                            style={{ display: "inline-block" }}
                                        >
                                            Mother Mobile No{" "}

                                            :{" "}</strong>
                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.Mother_Mobile}
                                        </span>
                                    </div>
                                    <span className="badge badge-phoenix badge-phoenix-primary mt-2">
                                        Note :</span>

                                    <div className="d-block flex-wrap mb-1 mt-2">

                                        <span className="fw-semi-bold ms-1 me-4">
                                            {studentData?.InactiveRemarks ? studentData?.InactiveRemarks : "--"}
                                        </span>
                                    </div>
                                    <span className="badge badge-phoenix badge-phoenix-primary mt-2">
                                        Financial Details :</span>
                                    <div className="d-flex mt-2">
                                        <div className="col-md-4">
                                            <strong>Total Fees</strong>
                                            <h5
                                                className="text-700"
                                            //style={{ marginLeft: "4px" }}
                                            >
                                                {feesData?.Final_T_Fees ?
                                                    parseFloat(feesData?.Final_T_Fees)
                                                        .toFixed(2) : "0"}
                                            </h5>
                                        </div>
                                        <VerticalDivider />
                                        <div className="col-md-4">
                                            <strong >Paid</strong>
                                            <h5
                                                className="text-700"
                                            //style={{ marginLeft: "-13px" }}
                                            >
                                                {feesData?.T_Paid ? parseFloat(feesData?.T_Paid).toFixed(2) : `${"              0"}`}
                                            </h5>
                                        </div>
                                        <VerticalDivider />
                                        <div className="col-md-4">
                                            <strong>Balance</strong>
                                            <h5
                                                className="text-700"
                                            //style={{ marginLeft: "-5px" }}
                                            >
                                                {feesData?.T_Bal ? parseFloat(feesData?.T_Bal).toFixed(2) : `${"              0"}`}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {loader}

            </div>

        </>


    );
};

export default StudentProfile;
